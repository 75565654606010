
body{
  margin: 0;
  font-size: 1rem;
  }
  #root {
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0 auto;
  }
#main{
  min-height: 75vh;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "Playfair Display", Georgia, "Times New Roman", serif /*rtl:Amiri, Georgia, "Times New Roman", serif*/;
}

.nav-item{
 /* border-right: solid #001f3f; 
  border-radius: 20%;*/
  text-align: center;
  box-shadow: 0px 1px 1px -1px rgba(15, 14, 14, 0.93);

}

.nav-item:hover{
 /* border-top: solid #001f3f; */
 background-color: #f8f9fa;
  color: rgba(6,78,59);
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  box-shadow: 0px 4px 4px -3px rgba(15, 14, 14, 0.93);
}

.nav-item a.active{
  background-color: #f8f9fa;
  color: rgba(6,78,59);
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  box-shadow: 0px 4px 4px -3px rgba(15, 14, 14, 0.93);
}

.glow {
 /* font-size: 80px; */
  color: #fff;
  text-align: center;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }
  
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}
.fadein {
  animation: fadeInAnimation ease 0.5s;
  animation-iteration-count: 1;
  /*animation-duration: 0.7s*/;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  background: #34568B;
  position: relative;
  border: 3px solid;
  border-color: #FFF #FFF transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #FF3D00 #FF3D00;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.loader::before {
  width: 32px;
  height: 32px;
  border-color: #FFF #FFF transparent transparent;
  animation: rotation 1.5s linear infinite;
}
   
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
    

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
.feature-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  margin-bottom: 1rem;
  font-size: 2rem;
  color: #fff;
  border-radius: .75rem;
}

.icon-link {
  display: inline-flex;
  align-items: center;
}
.icon-link > .bi {
  margin-top: .125rem;
  margin-left: .125rem;
  transition: transform .25s ease-in-out;
  fill: currentColor;
}
.icon-link:hover > .bi {
  transform: translate(.25rem);
}

.icon-square {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  border-radius: .75rem;
}


.card-cover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
/* Social buttons */
.socials {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 1vw;
  bottom: 35vh;
}

.socicon {
  width: 2rem;
  height: 2rem;
}
/* Social button ends */

/* Scroll to top button */
.scroll-up {
  position: fixed;
  right: 1vw;
  bottom: 10vh;
  cursor: pointer;
}

.up-arrow {
  width: 3rem;
  height: 3rem;
}

.zinc{
background: #ADA996;  /* fallback for old browsers */
background: -webkit-linear-gradient(to top, #EAEAEA, #DBDBDB, #F2F2F2, #ADA996);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to top, #EAEAEA, #DBDBDB, #F2F2F2, #ADA996); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
.cool{
  color: #F6F4F2 !important;
}
.quillEditor{
  width: 100%;
  height: auto;
  min-height: 400px;
  max-height: 800px;
}
#mainNav{
 background-image: linear-gradient(to top, #ffffff 0%, #00a9f7d1    100%);
}

.bg-top-botom  {
  background-image: linear-gradient(to top, #00a9f7d1   0%, #ffffff 100%);
}

.bg-botom-top  {
  background-image: linear-gradient(to top, #ffffff 0%, #55c1f4   100%);
}
footer  {
  background-image: linear-gradient(to top, #55c1f4  0%, #ffffff 100%);
}
.bg-sky{
  background-color: #ceecfa;
}
.app-bg-body{
  background-color: #F7F7F7;
}
.app-bg-green{
  background-color: #5EBEC4;
}
.app-bg-cherry{
  background-color: #F92C85;
}
